import env from './.env';
import { environment as defaultEnv } from './base.environment';

export const environment = {
  ...defaultEnv,
  metrics: false,
  notifyError: false,
  version: env.version + '-development',
  tier: 'develop',
  serverUrl: 'https://api-dev.tdshop.io',
  cookies: {
    ...defaultEnv.cookies,
    domain: 'nest-dev.tdshop.io'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
